<template>
  <b-card title="Detail Warranty Registration">
    <b-modal
      v-model="modalDeregisterItem"
      title="Deregister Item Paperless Warranty"
    >
      <!-- Alert before Deregister-->
      <b-container>
        <b-row>
          <b-col cols="12">
            <div>
              <p>
                Please confirm that you want to deregister this item :
              </p>
              <p>Model : {{ selectItem.itemModel }}</p>
              <p>Serial : {{ selectItem.serial }}</p>
              <span>Barcode : {{ selectItem.barcode }}</span>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDeregister"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="loadingWarranty"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center py-3"
      >
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Fetch Warranty Registration Info</p>
      </div>
    </b-modal>

    <b-modal v-model="promptEdit" size="lg" @ok="saveChanges()">
      <b-row>
        <b-col
          ><label>General Warranty Duration</label>
          <b-form-select
            v-model="item.generalWarrantyDuration"
            :options="generalDuration"
          ></b-form-select>
        </b-col>
        <b-col
          v-if="
            item.phlvl1 !== '05300' || warrantyData.registerType === 'aircon'
          "
        >
          <label>Compressor Warranty Duration</label>
          <b-form-select
            v-model="item.compressorWarrantyDuration"
            :options="compressorDuration"
            :disabled="item.phlvl2 !== '000'"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="warrantyData.registerType === 'airpur'"
          ><label>Purchase Date</label>
          <b-form-datepicker v-model="item.purchaseDate"></b-form-datepicker>
        </b-col>
        <b-col v-if="warrantyData.registerType === 'aircon'"
          ><label>installation Date</label>
          <b-form-datepicker
            v-model="item.installationDate"
          ></b-form-datepicker>
        </b-col>
        <b-col v-if="warrantyData.registerType === 'aircon'"
          ><label>installer</label>
          <b-form-input v-model="item.installerName"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          ><label>DO</label>
          <b-form-input v-model="item.sapDo" disabled></b-form-input>
        </b-col>
        <b-col
          ><label>Remarks <span style="color:red">(*)</span></label>
          <b-form-textarea v-model="item.remarks"></b-form-textarea>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="saveChanges()"
            :disabled="
              item.remarks === undefined ||
                item.remarks === null ||
                item.remarks === ''
            "
          >
            Save
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right mr-2"
            @click="promptEdit = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-container class="px-0 mx-0">
      <!-- <b-col cols="12">
          <label>Registration Date</label>
          <b-form-datepicker v-model="warrantyData.createdAt"></b-form-datepicker>
        </b-col>

        <b-col cols="12">
          <label>Warranty Status</label>
          <b-form-input
            v-model="warrantyData.registerStatus"
            disabled></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Error Code</label>
          <b-form-input
            v-model="warrantyErrorMessage"
            disabled></b-form-input>
        </b-col> -->
      <b-col>
        <b-row>
          <b-col cols="2"><span>Certificate Number</span></b-col>
          <b-col cols="3"
            ><span style="font-weight:bold">{{
              warrantyData.cert
            }}</span></b-col
          >
          <b-col cols="7"></b-col>
        </b-row>
      </b-col>
      <br />

      <b-row>
        <b-col cols="12">
          <label>Registration Type</label>
          <b-form-select
            v-model="warrantyData.registerType"
            :options="registerOptions"
          >
          </b-form-select>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>Customer Info</h5>

        <b-col cols="12">
          <label> Type</label>
          <b-form-select
            v-model="warrantyData.customerType"
            :options="endCostumerTypes"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12">
          <label> Salutation</label>
          <b-form-select
            v-model="warrantyData.salutation"
            :options="listSalutation"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12">
          <label>Company Name</label>
          <b-form-input
            v-model="warrantyData.companyName"
            placeholder="Company Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>First Name</label>
          <b-form-input
            v-model="warrantyData.nameFirst"
            placeholder="Customer Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Last Name</label>
          <b-form-input
            v-model="warrantyData.nameLast"
            placeholder="Customer Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Contact Number</label>
          <b-form-input
            v-model="warrantyData.phone"
            placeholder="Customer Phone"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Email</label>
          <b-form-input
            v-model="warrantyData.email"
            placeholder="Email"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>Customer Address</h5>

        <b-col cols="12">
          <label>Postal Code</label>
          <b-row>
            <b-col md="9" cols="12">
              <b-form-input
                v-model="warrantyData.postal"
                type="text"
                maxlength="6"
                placeholder="Enter Postal"
              ></b-form-input>
            </b-col>
            <b-col md="3" cols="12">
              <b-button
                @click="addAddressByPostal(warrantyData.postal)"
                variant="success"
                class="mr-1"
                >Auto Fill Address
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <label>Block No</label>
          <b-form-input
            v-model="warrantyData.address"
            placeholder="Customer Address"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Road Name</label>
          <b-form-input
            v-model="warrantyData.address2"
            placeholder="Customer Address"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Unit No</label>
          <b-form-input
            v-model="warrantyData.address3"
            placeholder="Customer Address"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Building Name</label>
          <b-form-input
            v-model="warrantyData.buildingName"
            placeholder="Building Name"
          ></b-form-input>
        </b-col>
        <b-col cols="12">
          <label>Building type</label>
          <b-form-select
            v-model="warrantyData.buildingType"
            placeholder="select Building Type"
            :options="buildingTypeOpt"
          >
          </b-form-select>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>
          {{
            warrantyData.registerType === "airpur"
              ? "Purchase Info"
              : "Installation Info"
          }}
        </h5>

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installer Name</label>
          <b-form-input
            v-model="warrantyData.installerName"
            placeholder="Installer Name"
          ></b-form-input>
        </b-col>
        <b-col cols="12" v-if="warrantyData.registerType === 'airpur'">
          <label>Purchase Date <span style="color:red">(*)</span></label>
          <b-form-datepicker
            :max="max"
            v-model="warrantyData.purchaseDate"
          ></b-form-datepicker>
        </b-col>

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installation Date <span style="color:red">(*)</span></label>
          <b-form-datepicker
            :max="max"
            v-model="warrantyData.installationDate"
          ></b-form-datepicker>
        </b-col>

        <b-col cols="12" class="hidden">
          <label>Installer Address</label>
          <b-form-input
            v-model="warrantyData.installerAddress"
            placeholder="Installer Address"
          ></b-form-input>
        </b-col>

        <!-- <b-col
   cols="12"
   v-if="warrantyData.registerType === 'aircon'">
          <label>Installation Date</label>
          <b-form-datepicker
            :max="max"
            v-model="warrantyData.installationDate"></b-form-datepicker>
        </b-col> -->
      </b-row>

      <b-row class="section-data">
        <h5>Promo Code</h5>
        <b-col cols="12">
          <label>Reference Code</label>
          <b-form-input
            v-model="warrantyData.promoCode"
            placeholder="Reference Code"
            disabled
          ></b-form-input>
        </b-col>
        <!-- <b-col cols="12">
          <label>P Code</label>
          <b-form-input
            v-model="warrantyData.pCode"
            placeholder="P Code"
            disabled></b-form-input>
        </b-col> -->
      </b-row>

      <div class="section-data">
        <h5>Invoice</h5>

        <!-- <validation-provider
                #default="{ errors }"
                name="Invoice Date"
                rules="required"
                tag="div"
                class="form-line"
              > -->
        <label>Invoice Date</label>

        <div class="form-validation-input">
          <b-form-datepicker
            placeholder="Select Invoice date"
            v-model="warrantyData.invoiceDate"
          ></b-form-datepicker>
        </div>
        <!-- </validation-provider> -->
        <b-modal size="xl" hide-footer v-model="showImageFile">
          <div
            class="py-2"
            style="display: flex; justify-content: center; align-content: center;"
          >
            <img
              style="cursor: pointer; max-width: 1280px; max-height: 720px; height: auto; width: auto;"
              @click="showImageFile = true"
              :src="getImgUrl(warrantyData.fileInvoice)"
            />
          </div>
        </b-modal>
        <label>Invoice File</label>
        <div class="form-line">
          <div style="display: flex; flex-direction: column;">
            <img
              class="mt-1"
              style="cursor: pointer;"
              @click="showImageFile = true"
              width="125"
              height="125"
              :src="getImgUrl(warrantyData.fileInvoice)"
            />
            <p style="color: red; margin-top: 4px" v-if="isValidImage">
              {{ isValidMessage }}
            </p>
          </div>
        </div>

        <!-- TODO: ADD upload filed -->
        <b-modal size="xl" hide-footer v-model="showImage">
          <div
            class="py-2"
            style="display: flex; justify-content: center; align-content: center;"
          >
            <img
              style="cursor: pointer; max-width: 1280px; max-height: 720px; height: auto; width: auto;"
              @click="showImage = true"
              v-if="selected"
              :src="previewInvoice"
            />
          </div>
        </b-modal>
        <div class="form-line">
          <label>Select Invoice</label>

          <b-form-file
            id="id1"
            @change="uploadInvoiceImage()"
            v-model="selected"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/jpeg, image/jpg, image/gif, image/gif"
          />

          <!-- <b-form-file
   v-model="selected"
   class="w-full mb-1" />
                <b-button @click="coba">ccc</b-button> -->
        </div>
        <div class="form-line">
          <div style="display: flex; flex-direction: column;">
            <img
              class="mt-1"
              style="cursor: pointer;"
              @click="showImage = true"
              width="125"
              height="125"
              v-if="selected"
              :src="previewInvoice"
            />
            <p style="color: red; margin-top: 4px" v-if="isValidImage">
              {{ isValidMessage }}
            </p>
          </div>
        </div>
      </div>

      <!-- <b-col
   cols="12"
   class="section-data my-2">
          <h5>Product Entry</h5>

          <b-form-group
              label="Item Model"
            >
              <b-form-input
                v-model="model"
                placeholder="Input Item Model">
              </b-form-input>
          </b-form-group>

          <b-form-group
            label="Item Serial"
          >
            <b-form-input
                v-model="serial"
                placeholder="Input Item Serial"
                >
            </b-form-input>
          </b-form-group>

          <b-button
            variant="outline-success"
            @click="manualAddItem">
            Add Item
          </b-button>
        </b-col> -->

      <b-col cols="12" class="my-2 section-data">
        <h5>Product registered</h5>

        <b-table
          :items="warrantyData.items"
          :fields="fields"
          ref="table"
          responsive
        >
          <template #cell(generalWarrantyEndDate)="row">
            {{
              row.item.generalWarrantyEndDate
                ? row.item.generalWarrantyEndDate
                : "-" | formatDate
            }}
          </template>

          <template #cell(compressorWarrantyEndDate)="row">
            {{
              row.item.compressorWarrantyEndDate
                ? row.item.compressorWarrantyEndDate
                : "-" | formatDate
            }}
          </template>

          <template #cell(actions)="row">
            <div class="d-flex">
              <!-- <b-button
                    variant="danger"
                    size="sm"
                    :disabled="!(warrantyData.registerStatus !== 'Registered')"
                    @click="confirmToDeleteItem(row)"
                    class="mr-1">
                  <b-icon icon="trash"></b-icon>
                </b-button> -->

              <!-- <b-button
                      variant="danger"
                      size="sm"
                      :disabled="!(warrantyData.registerStatus !== 'Registered')"
                      @click="resetItemStatus(row.item._id)"
                      class="mr-1">
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button> -->

              <b-button
                v-if="permission.edit"
                size="sm"
                variant="primary"
                @click="openModal(row.item)"
                class="mr-1"
              >
                <feather-icon icon="EditIcon" /> Edit</b-button
              >
              <div v-if="warrantyData.items.length > 1">
                <b-button
                  v-if="permission.edit"
                  variant="danger"
                  size="sm"
                  @click="deregisterItem(row.item)"
                >
                  <feather-icon icon="Trash2Icon" />
                  Deregister
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </b-col>

      <div class="mt-6 mb-6 d-flex flex-row-reverse">
        <b-button
          v-if="permission.cancel"
          class="ml-1"
          variant="danger"
          @click="cancelPaperlessWarranty"
          >Cancel Warranty</b-button
        >
        <b-button
          v-if="permission.deregister"
          class="ml-1"
          variant="danger"
          @click="deRegisterPaperlessWarranty"
          >DeRegister Warranty</b-button
        >
        <!-- <b-button
   v-if="permission.delete"
   class="ml-1"
   variant="danger"
   @click="deletePaperlessWarranty">Delete</b-button> -->
        <b-button variant="success" :disabled="onSubmit" @click="process">
          <template v-if="onSubmit">
            <div class="d-flex justify-content-center align-items-center">
              <b-spinner label="Loading..." class="mr-1"></b-spinner> Processing
            </div>
          </template>

          <template v-else>
            Save Changes
          </template>
        </b-button>
        <b-button @click="$router.go(-1)" class="mr-1" variant="primary"
          >Back</b-button
        >
      </div>
    </b-container>
    <!-- <b-modal
   hide-footer
   v-model="deleteModal">
      <p>Please confirm you want to delete this item? </p>

      <b-button
        class="mt-2 mr-1"
        @click="doDeleteItem">Yes</b-button>
      <b-button
        class="mt-2"
        @click="deleteModal = false">No</b-button>
    </b-modal> -->
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { userAccess } from "@/utils/utils";
import { sleep } from "@/utils/utils.js";
export default {
  props: ["id"],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      selectItem: "",
      modalDeregisterItem: false,
      max: maxDate,
      fields: [
        {
          key: "itemModel",
          label: "Model",
        },
        {
          key: "serial",
          label: "Serial",
        },
        {
          key: "barcode",
          label: "Barcode",
        },
        {
          key: "sapDo",
          label: "DO",
        },
        {
          key: "pcode",
          label: "PCode",
        },
        {
          key: "warrantyStatus",
          label: "Warrany Status",
        },
        {
          key: "generalWarrantyEndDate",
          label: "General Warranty End",
        },
        {
          key: "compressorWarrantyEndDate",
          label: "Compressor Warranty End",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      warrantyData: {
        invoiceDate: "",
        fileInvoice: "",
      },
      items: [],
      endCostumerTypes: [
        {
          value: "Company",
          text: "Company",
        },
        {
          value: "Individual",
          text: "Individual",
        },
      ],
      selectedItem: null,
      nonSaleItems: [],
      registerOptions: [
        {
          value: "aircon",
          text: "Air Conditioner",
        },
        {
          value: "airpur",
          text: "Air Purifier",
        },
      ],
      /*
      generalDuration: [{
          value: 12,
          text: "12 Months"
        },
        {
          value: 13,
          text: "13 Months"
        },
        {
          value: 14,
          text: "14 Months"
        },
        {
          value: 15,
          text: "15 Months"
        },
        {
          value: 16,
          text: "16 Months"
        },
        {
          value: 17,
          text: "17 Months"
        },
        {
          value: 18,
          text: "18 Months"
        },
        {
          value: 19,
          text: "19 Months"
        },
        {
          value: 20,
          text: "20 Months"
        },
        {
          value: 21,
          text: "21 Months"
        },
        {
          value: 22,
          text: "22 Months"
        },
        {
          value: 23,
          text: "23 Months"
        },
        {
          value: 24,
          text: "24 Months"
        },
        {
          value: 25,
          text: "25 Months"
        },
        {
          value: 26,
          text: "26 Months"
        },
        {
          value: 27,
          text: "27 Months"
        },
        {
          value: 28,
          text: "28 Months"
        },
        {
          value: 29,
          text: "29 Months"
        },
        {
          value: 30,
          text: "30 Months"
        },
        {
          value: 31,
          text: "31 Months"
        },
        {
          value: 32,
          text: "32 Months"
        },
        {
          value: 33,
          text: "33 Months"
        },
        {
          value: 34,
          text: "34 Months"
        },
        {
          value: 35,
          text: "35 Months"
        },
        {
          value: 36,
          text: "36 Months"
        },
        {
          value: 37,
          text: "37 Months"
        },
        {
          value: 38,
          text: "38 Months"
        },
        {
          value: 39,
          text: "39 Months"
        },
        {
          value: 40,
          text: "40 Months"
        },
        {
          value: 41,
          text: "41 Months"
        },
        {
          value: 42,
          text: "42 Months"
        },
        {
          value: 43,
          text: "43 Months"
        },
        {
          value: 44,
          text: "44 Months"
        },
        {
          value: 45,
          text: "45 Months"
        },
        {
          value: 46,
          text: "46 Months"
        },
        {
          value: 47,
          text: "47 Months"
        },
        {
          value: 48,
          text: "48 Months"
        },
        {
          value: 49,
          text: "49 Months"
        },
        {
          value: 50,
          text: "50 Months"
        },
        {
          value: 51,
          text: "51 Months"
        },
        {
          value: 52,
          text: "52 Months"
        },
        {
          value: 53,
          text: "53 Months"
        },
        {
          value: 54,
          text: "54 Months"
        },
        {
          value: 55,
          text: "55 Months"
        },
        {
          value: 56,
          text: "56 Months"
        },
        {
          value: 57,
          text: "57 Months"
        },
        {
          value: 58,
          text: "58 Months"
        },
        {
          value: 59,
          text: "59 Months"
        },
        {
          value: 60,
          text: "60 Months"
        },
        {
          value: 61,
          text: "61 Months"
        },
        {
          value: 62,
          text: "62 Months"
        },
      ],
      compressorDuration: [{
          value: 12,
          text: "12 Months"
        },
        {
          value: 13,
          text: "13 Months"
        },
        {
          value: 14,
          text: "14 Months"
        },
        {
          value: 15,
          text: "15 Months"
        },
        {
          value: 16,
          text: "16 Months"
        },
        {
          value: 17,
          text: "17 Months"
        },
        {
          value: 18,
          text: "18 Months"
        },
        {
          value: 19,
          text: "19 Months"
        },
        {
          value: 20,
          text: "20 Months"
        },
        {
          value: 21,
          text: "21 Months"
        },
        {
          value: 22,
          text: "22 Months"
        },
        {
          value: 23,
          text: "23 Months"
        },
        {
          value: 24,
          text: "24 Months"
        },
        {
          value: 25,
          text: "25 Months"
        },
        {
          value: 26,
          text: "26 Months"
        },
        {
          value: 27,
          text: "27 Months"
        },
        {
          value: 28,
          text: "28 Months"
        },
        {
          value: 29,
          text: "29 Months"
        },
        {
          value: 30,
          text: "30 Months"
        },
        {
          value: 31,
          text: "31 Months"
        },
        {
          value: 32,
          text: "32 Months"
        },
        {
          value: 33,
          text: "33 Months"
        },
        {
          value: 34,
          text: "34 Months"
        },
        {
          value: 35,
          text: "35 Months"
        },
        {
          value: 36,
          text: "36 Months"
        },
        {
          value: 37,
          text: "37 Months"
        },
        {
          value: 38,
          text: "38 Months"
        },
        {
          value: 39,
          text: "39 Months"
        },
        {
          value: 40,
          text: "40 Months"
        },
        {
          value: 41,
          text: "41 Months"
        },
        {
          value: 42,
          text: "42 Months"
        },
        {
          value: 43,
          text: "43 Months"
        },
        {
          value: 44,
          text: "44 Months"
        },
        {
          value: 45,
          text: "45 Months"
        },
        {
          value: 46,
          text: "46 Months"
        },
        {
          value: 47,
          text: "47 Months"
        },
        {
          value: 48,
          text: "48 Months"
        },
        {
          value: 49,
          text: "49 Months"
        },
        {
          value: 50,
          text: "50 Months"
        },
        {
          value: 51,
          text: "51 Months"
        },
        {
          value: 52,
          text: "52 Months"
        },
        {
          value: 53,
          text: "53 Months"
        },
        {
          value: 54,
          text: "54 Months"
        },
        {
          value: 55,
          text: "55 Months"
        },
        {
          value: 56,
          text: "56 Months"
        },
        {
          value: 57,
          text: "57 Months"
        },
        {
          value: 58,
          text: "58 Months"
        },
        {
          value: 59,
          text: "59 Months"
        },
        
      ],
      */
      deleteModal: false,
      idTodelete: null,
      indexToDelete: 0,
      listSalutation: [
        {
          value: "MR",
          text: "Mr.",
        },
        {
          value: "MS",
          text: "Ms.",
        },
      ],
      model: "",
      serial: "",
      item: {},
      onSubmit: false,
      loadingWarranty: false,
      promptEdit: false,
      buildingTypeOpt: [
        {
          value: "",
          text: "Select Building Type",
          disabled: true,
        },
        "HDB",
        "Condominium",
        "Private Housing",
        "Commercial Building",
      ],
      previewInvoice: "",
      showImage: false,
      showImageFile: false,
      isValidImage: null,
      isValidMessage: "",
      selected: null,
      image: "",
    };
  },
  mounted() {
    this.$store.dispatch("warranty/getOptionWarranty");
    axios
      .get(
        `/sales-and-purchase/drcc/external-warranty/detail/${this.$route.params.id}`
      )
      .then((res) => {
        this.warrantyData = res.data.data;
        if (this.warrantyData.cert.endsWith("AC")) {
          this.warrantyData.registerType = "aircon";
        } else {
          this.warrantyData.registerType = "airpur";
        }
        console.log("warrData", this.warrantyData);
      })
      .catch((err) =>
        console.log({
          err,
        })
      );
  },
  computed: {
    permission() {
      return userAccess("Paperless Warranty", "warranty_menu");
    },
    monthOption() {
      return this.$store.getters["warranty/getOptionWarranty"];
    },
    generalDuration() {
      return this.monthOption.generalDuration;
    },
    compressorDuration() {
      return this.monthOption.compressorDuration;
    },

    isAirPurified() {
      let flag =
        (this.warrantyData.purchaseDate !== undefined) &
        (this.warrantyData.purchaseDate !== null);
      if (flag) {
        return true;
      }

      return false;
    },
    warrantyErrorMessage: {
      get() {
        let { errorCode, errorStatus } = this.warrantyData;

        if (errorCode !== undefined || errorStatus !== undefined) {
          return `${errorCode} - ${errorStatus}`;
        }

        return ``;
      },
      set(val) {},
    },
  },
  methods: {
    getImgUrl(img) {
      if (img == "" || img == undefined) {
        return "https://rsp-production-space.sgp1.digitaloceanspaces.com/no-image-icon.png";
      }
      var path =
        "https://rsp-production-space.sgp1.digitaloceanspaces.com/rsp/";
      // console.log("PATH", path);
      return path + "" + img;
    },
    isEmpty(str) {
      return !str || str.length === 0;
    },
    saveChanges() {
      // let findID = this.warrantyData.items.find((x) => x._id === this.item._id);
      // let temp = findID
      // console.log({
      //   findID
      // });
      if (this.item.phlvl1 !== "05300" && this.item.phlvl2 === "000") {
        // console.log("hit");
        this.item.compressorWarrantyDuration = parseInt(
          this.item.compressorWarrantyDuration
        );
        let compressDate = new Date(this.item.installationDate);
        let comWarDate = compressDate.setMonth(
          compressDate.getMonth() + this.item.compressorWarrantyDuration
        );
        compressDate.setDate(compressDate.getDate() - 1);

        this.item.compressorWarrantyEndDate = new Date(compressDate);
        // console.log({compressDate})
      }
      if (this.warrantyData.registerType === "airpur") {
        this.item.generalWarrantyDuration = parseInt(
          this.item.generalWarrantyDuration
        );
        let purchaseDate = new Date(this.item.purchaseDate);
        let genWarDate = purchaseDate.setMonth(
          purchaseDate.getMonth() + this.item.generalWarrantyDuration
        );
        purchaseDate.setDate(purchaseDate.getDate() - 1);

        this.item.generalWarrantyEndDate = new Date(purchaseDate);
      } else {
        this.item.generalWarrantyDuration = parseInt(
          this.item.generalWarrantyDuration
        );

        let InstallerDate = new Date(this.item.installationDate);

        InstallerDate.setMonth(
          InstallerDate.getMonth() + this.item.generalWarrantyDuration
        );
        InstallerDate.setDate(InstallerDate.getDate() - 1);

        // console.log({genWarDate})

        this.item.generalWarrantyEndDate = new Date(InstallerDate);
      }

      var foundIndex = this.warrantyData.items.findIndex(
        (x) => x._id == this.item._id
      );
      this.warrantyData.items[foundIndex] = this.item;

      // console.log('after Find ID',findID)

      this.$refs.table.refresh();
      this.promptEdit = false;
      // console.log('ITEM',this.item)
      // console.log('warranty data',this.warrantyData)
    },
    openModal(data) {
      this.promptEdit = true;
      this.item = Object.assign(
        {
          ...data,
        },
        {
          purchaseDate: this.warrantyData.purchaseDate
            ? this.warrantyData.purchaseDate
            : "",
        }
      );
      // this.item = data
      // console.log('itemss',this.item)
    },
    process() {
      if (this.onSubmit) {
        console.log("Still Processing Item");
        // Just Prevent Processing Logic To Continue
        return;
      }

      let rawData = JSON.stringify(this.warrantyData);
      let data = JSON.parse(rawData);

      if (data.registerType === "airpur") {
        data.installerName = "";
        data.installationDate = "";
      } else {
        data.purchaseDate = "";
      }

      this.onSubmit = true;
      console.log({
        data,
      });

      return this.editWarranty(data)
        .then(async () => {
          // this.getWarrantyData();
          // this.getAllItems();

          this.$bvToast.toast("Successfully update Paperless Warranty", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.onSubmit = false;
          await sleep(1000);
          this.$router.go(-1);
        })
        .catch((err) => {
          this.onSubmit = false;
          console.log({
            err,
          });
          let errorMessage = err.response.data.errors[0].message;

          if (errorMessage !== undefined) {
            this.$bvToast.toast(errorMessage, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Error Processing Data", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
      // this.$router.go(-1)
      // alert(warrantyData)
    },
    getWarrantyData() {
      axios
        .get(
          `sales-and-purchase/drcc/external-warranty/detail/${this.$route.params.id}`
        )
        .then((response) => {
          this.warrantyData = response.data.data;
          this.loadingWarranty = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllItems() {
      axios
        .get(`sales-and-purchase/drcc/postal/non-order-items`)
        .then((response) => {
          this.nonSaleItems = response.data.data.map((item) => {
            return Object.assign(
              {},
              {
                value: item,
                text: `${item.itemModel} - ${item.barcode}`,
              }
            );
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addItem() {
      this.warrantyData.items.push(this.selectedItem);
    },
    deleteItem(row) {
      this.warrantyData.items.splice(row.index, 1);
    },
    confirmToDeleteItem(row) {
      this.deleteModal = true;
      this.idTodelete = row.item._id;
      this.indexToDelete = row.index;
    },
    deletePaperlessWarranty() {
      if (confirm("Please confirm you want to delete this draft warranty? ")) {
        this.deleteExternalWarranty(this.warrantyData._id).then((result) => {
          if (result) {
            this.$router.go(-1);
          }
        });
      }
    },
    cancelPaperlessWarranty() {
      let rawData = JSON.stringify(this.warrantyData);
      let data = JSON.parse(rawData);
      if (confirm("Please confirm. Do you want to cancel this warranty? ")) {
        this.cancelWarranty(data).then((result) => {
          if (result) {
            this.$router.go(-1);
          }
        });
      }
    },
    deRegisterPaperlessWarranty() {
      let rawData = JSON.stringify(this.warrantyData);
      let data = JSON.parse(rawData);
      if (
        confirm("Please confirm. Are you sure want to reset this warranty? ")
      ) {
        this.deregisterWarranty(data).then(async (result) => {
          if (result) {
            this.$bvToast.toast(`Process De-Register Warranty Success`, {
              title: "Success",
              variant: "success",
              solid: true,
            });
            await sleep(1500);
            this.$router.go(-1);
          }
        });
      }
    },
    deregisterItem(row) {
      this.selectItem = row;
      this.modalDeregisterItem = true;
    },
    confirmDeregister() {
      axios
        .put(
          `sales-and-purchase/warranty/deregister-item-paperless/${this.selectItem._id}`
        )
        .then(async (reset) => {
          console.log({ reset });

          this.$bvToast.toast(
            `Deregister Item Paperless Warranty Successfully`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.getWarrantyData();
          this.modalDeregisterItem = false;
        })
        .catch((e) => {
          console.log({ e });
          if (e.request.status === 400) {
            this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(`${e.response.data.message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    doDeleteItem() {
      this.deleteWarrantyItemId({
        id: this.idTodelete,
        warrantyId: this.warrantyData._id,
      })
        .then(() => {
          this.warrantyData.items.splice(this.indexToDelete, 1);
          this.getWarrantyData();
          this.deleteModal = false;
        })
        .catch((e) => {
          this.deleteModal = false;
        });
    },

    processWarranty() {
      axios
        .put(`sales-and-purchase/drcc/process/warranty`, this.warrantyData, {
          params: {
            type: "External",
          },
        })
        .then((response) => {
          this.$bvToast.toast(`Process Warranty Registration Success`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((e) => {
          this.$bvToast.toast(`Process Warranty Registration Failed`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    manualAddItem() {
      axios
        .post(`sales-and-purchase/drcc/warranty/admin-verify`, {
          registerType: this.warrantyData.registerType,
          serial: this.serial,
          model: this.model,
        })
        .then((response) => {
          this.warrantyData.items.push(response.data.data);
          this.barcode = "";
        })
        .catch((e) => {
          this.$bvToast.toast(`${e.response.data.message}`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    resetItemStatus(id) {
      if (confirm("Are You Sure To Reset Equipment Warranty Status ?")) {
        axios
          .put("sales-and-purchase/drcc/reset-item-status", {
            itemId: id,
          })
          .then((response) => {
            // this.getWarrantyData();
            this.getAllItems();

            let items = JSON.parse(JSON.stringify(this.warrantyData.items));
            let itemIndex = items.findIndex((item) => item._id === id);
            if (itemIndex > -1) {
              let item = items[itemIndex];
              item.warrantyStatus = "Pre";
              items.splice(itemIndex, 1, item);
            }
            this.warrantyData = Object.assign({}, this.warrantyData, {
              items: items,
            });

            this.$bvToast.toast(`Reset Item Warranty Status Success`, {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((e) => {
            console.log(e);
            this.$bvToast.toast(`Reset Item Warranty Status Failed`, {
              title: "Fail",
              variant: "danger",
              solid: true,
            });
          });
      }
    },
    ...mapActions({
      getAddressByPostal: "mapAPI/getAddressByPostal",
      deleteWarrantyItemId: "warranty/deleteExternalWarrantyItem",
      deleteExternalWarranty: "warranty/deleteExternalWarranty",
      getUsersBy: "auth/getUsersBy",
      addNewUser: "auth/newUser",
      editWarranty: "warranty/updateWarranty",
      cancelWarranty: "warranty/cancelWarranty",
      deregisterWarranty: "warranty/deregisterWarranty",
    }),
    async addAddressByPostal(postal) {
      if (postal.length != 6) {
        this.$bvToast.toast(`Postal not valid`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var address;
      try {
        address = await this.getAddressByPostal(postal);
      } catch (err) {
        this.$bvToast.toast(`One Map API Returns Error`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      console.info("address ", address);

      if (address.length <= 0) {
        this.$bvToast.toast("Postal " + postal + " has no address", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var arrayStreet = [];
      var arrayBuildingName = [];
      var arrayBlockNumber = [];
      for (let postalData of address) {
        let { ROAD_NAME, BLK_NO, BUILDING } = postalData;
        arrayStreet.push(ROAD_NAME);
        arrayBuildingName.push(BUILDING);
        arrayBlockNumber.push(BLK_NO);
      }
      let newSetArrayStreet = [...new Set(arrayStreet)];
      let newSetArrayBuildingName = [...new Set(arrayBuildingName)];
      let newSetArrayBlockNumber = [...new Set(arrayBlockNumber)];

      if (newSetArrayBlockNumber.length > 1) {
        this.arrayBuildingNo = newSetArrayBlockNumber;
        this.isBuildingNoArray = true;
      } else {
        this.warrantyData.address = newSetArrayBlockNumber[0];
        this.isBuildingNoArray = false;
      }

      if (newSetArrayStreet.length > 1) {
        this.arrayStreet = newSetArrayStreet;
        this.isStreetArray = true;
      } else {
        this.warrantyData.address2 = newSetArrayStreet[0];
        this.isStreetArray = false;
      }

      if (newSetArrayBuildingName.length > 1) {
        this.arrayBuildingName = newSetArrayBuildingName;
        this.isBuildingNameArray = true;
      } else {
        this.warrantyData.buildingName = newSetArrayBuildingName[0];
        this.isBuildingNameArray = false;
      }
      this.$bvToast.toast(`Address found`, {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 20px 0 20px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}
</style>
